import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{margin: 0}}>
          Selamat Datang di
        </p>
        <h1>
          PT. Ergyor Bintan Energi
        </h1>
        <a
          className="App-link"
          href="https://pm.erbigi.id"
          rel="noopener noreferrer"
          style={{textDecoration: 'none'}}
        >
          Project Management
        </a>
      </header>
    </div>
  );
}

export default App;
